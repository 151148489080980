import React, { useEffect, useRef } from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import ChildPages from "../components/ChildPages";
import BackgroundImage from "gatsby-background-image";
import SEO from "../components/seo";

// import { Parallax, Background } from "react-parallax";
import Rellax from "rellax";

/* can't get variable with static query, so using dynamic and exporting it */

export const query = graphql`
  query($slug: String!, $hero: String!) {
    wordpressPage(slug: { eq: $slug }) {
      title
      date
      content
      wordpress_id
      featured_media {
        localFile {
          childImageSharp {
            fixed(width: 800) {
              ...GatsbyImageSharpFixed
            }
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
    file(relativePath: { eq: $hero }) {
      childImageSharp {
        fluid(maxWidth: 1500, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;

const Page = (props) => {
  const rellaxRef = useRef();
  useEffect(() => {
    new Rellax(".animate", {
      // <---- Via class name
      speed: -5,
      center: false,
      wrapper: null,
      round: true,
      vertical: true,
      horizontal: false,
    });

    new Rellax(rellaxRef.current, {
      // <---- Via useRef element
      speed: -7,
      center: false,
      wrapper: null,
      round: true,
      vertical: true,
      horizontal: false,
    });
    if (document.location.hash === "#careers") {
      setTimeout(() => {
        let element = document.querySelector("#careers");
        element && element.scrollIntoView({ behavior: "smooth", block: "center" });
      }, 300);
    }
    if (document.location.hash === "#ourpeople") {
      setTimeout(() => {
        let element = document.querySelector("#ourpeople");
        element && element.scrollIntoView({ behavior: "smooth", block: "center" });
      }, 300);
    }
    if (document.location.hash === "#about") {
      setTimeout(() => {
        let element = document.querySelector("#about");
        element && element.scrollIntoView({ behavior: "smooth", block: "center" });
      }, 300);
    }
    if (document.location.hash === "#audits") {
      setTimeout(() => {
        let element = document.querySelector("#audits");
        element && element.scrollIntoView({ behavior: "smooth", block: "center" });
      }, 300);
    }
    if (document.location.hash === "#estate") {
      setTimeout(() => {
        let element = document.querySelector("#estate");
        element && element.scrollIntoView({ behavior: "smooth", block: "center" });
      }, 300);
    }
    if (document.location.hash === "#planning") {
      setTimeout(() => {
        let element = document.querySelector("#planning");
        element && element.scrollIntoView({ behavior: "smooth", block: "center" });
      }, 300);
    }
    if (document.location.hash === "#strategic") {
      setTimeout(() => {
        let element = document.querySelector("#strategic");
        element && element.scrollIntoView({ behavior: "smooth", block: "center" });
      }, 300);
    }
  }, []);
  console.log("props", props);

  return (
    <Layout>
      <SEO title={props.data.wordpressPage.title} />
      {props.data.file ? (
        <div className="image--wrapper">
          {/* <div className="page--overlay"> */}
          <h1 ref={rellaxRef} className="page--title">
            {props.data.wordpressPage.title}
          </h1>
          {/* <h1 className="page--title">{props.data.wordpressPage.title}</h1> */}
          {/* </div> */}
          {/* {props.data.file.childImageSharp.fluid ? ( */}
          {props.data.wordpressPage.featured_media ? (
            <>
              <Img
                className="hero animate"
                // fluid={props.data.wordpressPage.featured_media.source_url}
                fluid={props.data.wordpressPage.featured_media.localFile.childImageSharp.fluid}
              />
            </>
          ) : (
            // <BackgroundImage
            //   Tag="section"
            //   className="background-image-parallax"
            //   fluid={props.data.file.childImageSharp.fluid}
            //   backgroundColor={`#040e18`}
            // ></BackgroundImage>
            //  ) : (
            // <Parallax
            //   blur={10}
            //   bgImage={require("/static/aaf0f625b93c38a8438f942377602222/0f3a1/advisory.jpg")}
            //   bgImageAlt="the cat"
            //   strength={200}
            // >
            //   Put some text content here or even an empty div with fixed dimensions to have a height
            //   for the parallax.
            //   <div style={{ height: "200px" }} />
            // </Parallax>
            <div></div>
            // <Img className="hero" fluid={props.data.file.childImageSharp.fluid} />
          )}

          {/* <Img className="hero" fluid={props.data.file.childImageSharp.fluid} /> */}
        </div>
      ) : (
        <div></div>
      )}
      <div className="container container--page">
        <div dangerouslySetInnerHTML={{ __html: props.data.wordpressPage.content }}></div>
      </div>

      <ChildPages forParent={props.data.wordpressPage.wordpress_id} />
    </Layout>
  );
};

export default Page;
